<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 60%" @submit.prevent="save">
        <b-row>
          <b-col md="12">
            
       <b-col md="12">
            <b-form-group
              label="About Page :"
              label-for="blog-edit-course"
              class="mb-2"
            >
               <label> 


Become an Instructor</label>
            </b-form-group>
          </b-col>
          
          <b-col cols="12">
            <b-form-group
              label="Title"
              label-for="blog-content"
              class="mb-2"
            >
            <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
               <b-form-input
     
    v-model="addCourseForm.course"
    />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        
          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="blog-content"
              class="mb-2"
            >
            <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                <b-form-input
     
    v-model="addCourseForm.course"
    />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
           <b-col cols="12">
            <b-form-group
              label="Keywords"
              label-for="blog-content"
              class="mb-2"
            >
            <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                <b-form-input
     
    v-model="addCourseForm.course"
    />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          
          </b-col>
          
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
           
          </b-col>
        </b-row>
      </b-form>

    </validation-observer>

    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTable
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from '@/store'
import Vue from "vue";

export default {
  setup() {
 
    // const store = useStore();
    const refInputEl = ref(null);
      const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
     const refPreviewE2 = ref(null);
    const Tabimage = ref("");
    const coursesList=ref([])
  
 store.dispatch('categories/AllCategory')
        .then(response => {
          
          coursesList.value=response.data.data
        })
    const addCourseForm = reactive({
      is_free: "",
      name: "",
      link_id: "",
      tab_image: [],
      header_image: [],
      alt_tab_image: "",
      alt_header_image: "",
      duration: "",
      video_link: "",
      price: "",
    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
     const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
     const addCourseFormvalidate=ref()

    const save = () => {
      
      
      const formData = new FormData();

    
        
         addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append("is_free", addCourseForm.is_free);
formData.append("name", addCourseForm.name);
formData.append("header_image", addCourseForm.header_image);
formData.append("name", addCourseForm.name);
formData.append("link_id", addCourseForm.link_id);
formData.append("tab_image", addCourseForm.tab_image);
formData.append(" header_image", addCourseForm.header_image);
formData.append("alt_tab_image", addCourseForm.alt_tab_image);
formData.append("alt_header_image", addCourseForm.alt_header_image);
formData.append("duration", addCourseForm.duration);
formData.append(" video_link", addCourseForm. video_link);
formData.append("price", addCourseForm.price);
      
           store.dispatch('othercourses/addCourse',formData)
        .then(response => {
           Vue.swal({
                       title: "Course Added ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
     
        }})
    };
    const tableColumns=[
    
          { key: 'id',label:"ID"},
    { key:"name",label: 'About Page '},
       { label: 'Title',key:'link_id'},
          { key:"name",label: 'Descripation'},
       { label: 'KeyWords',key:'Date'},
          
      
    
    { key: 'actions' },
    
]
    return {
      refInputEl,
      refPreviewEl,
      tableColumns,
      refInputE2,
       refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
  
     

      save,
       required, email
    };
  },

  components: {
    BCard,
        BTable,
    BMedia,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
  
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
